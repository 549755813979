import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { baseUrl } from '../utils';
import axios from 'axios';

const initialState = {
  isLoading: false,
  data: null,
  coverage: null,
  stats: {},
  redemptions: [],
  statLoading: false,
};

export const getCoverageInfo = createAsyncThunk('dashboard/getCoverageInfo', async () => {
  try {
    let token = localStorage.getItem('vendorToken');
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/employer/report/snapshot`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getMerchantVouchers = createAsyncThunk('getMerchantVouchers', async ({ filters }) => {
  const { voucherCode, startDate, endDate, itemRedeemed } = filters;
  let str = '';
  if (voucherCode) {
    str = `voucherCode=${voucherCode}`;
  }
  if (startDate) {
    str = `${str}&fromDate=${startDate}`;
  }
  if (endDate) {
    str = `${str}&toDate=${endDate}`;
  }
  if (itemRedeemed) {
    str = `${str}&itemRedeemed=${itemRedeemed}`;
  }
  try {
    let token = localStorage.getItem('vendorToken');
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/merchantVouchers${str ? `?${str}` : ''}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const getMerchantVoucherStats = createAsyncThunk(
  'getMerchantVoucherStats',
  async ({ filters }) => {
    const { startDate, endDate, monthAndYear } = filters;
    let str = '';

    if (startDate) {
      str = `${str}&fromDate=${startDate}`;
    }
    if (endDate) {
      str = `${str}&toDate=${endDate}`;
    }
    if (monthAndYear) {
      str = `${str}&monthAndYear=${monthAndYear}`;
    }

    try {
      let token = localStorage.getItem('vendorToken');
      const response = await axios({
        method: 'GET',
        url: `${baseUrl}/merchantVouchers/stats${str ? `?${str}` : ''}`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const Dashboard = createSlice({
  name: 'Dashboard',
  initialState: initialState,
  reducers: {
    logOut: (state) => {
      state.loading = false;
      state.isAuthenticated = false;
      state.token = null;
      localStorage.clear();
    },
  },
  extraReducers(builder) {
    builder.addCase(getCoverageInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCoverageInfo.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.result) {
        state.coverage = action.payload.result;
      }
    });
    builder.addCase(getCoverageInfo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getMerchantVoucherStats.pending, (state) => {
      state.statLoading = true;
    });
    builder.addCase(getMerchantVoucherStats.fulfilled, (state, action) => {
      state.statLoading = false;
      if (action.payload?.api?.responseCode === 2040) {
        state.stats = action?.payload?.result;
      } else {
        state.stats = {};
      }
    });
    builder.addCase(getMerchantVoucherStats.rejected, (state) => {
      state.statLoading = false;
    });

    builder.addCase(getMerchantVouchers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMerchantVouchers.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.api?.responseCode === 2040) {
        state.redemptions = action?.payload?.result?.content;
      } else {
        state.redemptions = [];
      }
    });
    builder.addCase(getMerchantVouchers.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default Dashboard.reducer;
