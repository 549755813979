import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../utils';

const initialState = {
  isLoading: false,
  catalogues: {},
  discounts: [],
};

export const merchantRewardCatalogues = createAsyncThunk('merchantRewardCatalogues', async () => {
  try {
    let token = localStorage.getItem('vendorToken');
    let userId = JSON.parse(localStorage.getItem('vendorUser')).parent_account_id;
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/merchantRewardCatalogues/merchant/${userId}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const merchantRewardCataloguesDiscounts = createAsyncThunk(
  'merchantRewardCataloguesDiscounts',
  async () => {
    try {
      let token = localStorage.getItem('vendorToken');
      const response = await axios({
        method: 'GET',
        url: `${baseUrl}/merchantRewardCatalogues/merchant/catalogues-with-discounts`,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
);

export const CatalogueSlice = createSlice({
  name: 'CatalogueSlice',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(merchantRewardCatalogues.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(merchantRewardCatalogues.fulfilled, (state, action) => {
      state.isLoading = false;
      state.catalogues = action.payload?.catalogues;
    });
    builder.addCase(merchantRewardCatalogues.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(merchantRewardCataloguesDiscounts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(merchantRewardCataloguesDiscounts.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.merchant_discounts.length > 0) {
        state.discounts = action.payload.merchant_discounts?.[0];
      } else {
        state.discounts = [];
      }
    });
    builder.addCase(merchantRewardCataloguesDiscounts.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default CatalogueSlice.reducer;
