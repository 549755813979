import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import LoaderComponenet from '../components/UIComponents/Loader';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const Login = lazy(() => import('../pages/Login'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const Support = lazy(() => import('../pages/Support'));
const Profile = lazy(() => import('../pages/Profile'));
const Welcome = lazy(() => import('../pages/Welcome'));
const Settings = lazy(() => import('../pages/Settings'));
const Redemptions = lazy(() => import('../pages/Redemptions'));
const Categories = lazy(() => import('../pages/Categories'));
const Payments = lazy(() => import('../pages/Payments'));
const NotFound = lazy(() => import('../pages/404'));

const RouteSetup = () => {
  return (
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        <Route path="/welcome" element={<Welcome />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/redemptions"
          element={
            <PrivateRoute>
              <Redemptions />
            </PrivateRoute>
          }
        />
        <Route
          path="/catalogues"
          element={
            <PrivateRoute>
              <Categories />
            </PrivateRoute>
          }
        />
        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <Payments />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/support"
          element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/verify-email" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
