import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCANmOEE0Lec840y1_3-dJRs4IIDjJ7w34',
  authDomain: 'eden-cms-54719.firebaseapp.com',
  databaseURL: 'https://eden-cms-54719-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'eden-cms-54719',
  storageBucket: 'eden-cms-54719.appspot.com',
  messagingSenderId: '104103528113',
  appId: '1:104103528113:web:63a8fc0de950e37850c490',
  measurementId: 'G-8YXRZVRH4Z',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      'BHNlEpLODaG3lFaTycHqo17qFPJvB1QB9mEhMKt13DB6uEO_H7NwNjUbS_HoV-3MHLZBrB6J_n3oUyW27_pbsn0',
  })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(currentToken);
      } else {
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const storageRef = ref(app);
export const auth = getAuth(app);
