import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../utils';

const initialState = {
  payments: [],
  isLoading: false,
};

export const getPayments = createAsyncThunk('getPayments', async ({ filters }) => {
  const { monthAndYear, status } = filters;
  let str = '';
  if (monthAndYear) {
    str = `monthAndYear=${monthAndYear}`;
  }
  if (status) {
    str = `${str}&status=${status}`;
  }
  try {
    let token = localStorage.getItem('vendorToken');
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/merchantVouchers/paymentBatch${str ? `?${str}` : ''}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) {
      throw new Error('Failed to fetch data');
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const paymentSlice = createSlice({
  name: 'paymentSlice',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getPayments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPayments.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.api?.responseCode === 2040) {
        state.payments = action?.payload?.result;
      } else {
        state.payments = [];
      }
    });
    builder.addCase(getPayments.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default paymentSlice.reducer;
