import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { baseUrl } from '../utils';

const initialState = {
  redemptions: [],
  isLoading: false,
};

export const getMerchantVouchers = createAsyncThunk('getMerchantVouchers', async ({ filters }) => {
  const { voucherCode, startDate, endDate, itemRedeemed } = filters;
  let str = '';
  if (voucherCode) {
    str = `voucherCode=${voucherCode}`;
  }
  if (startDate) {
    str = `${str}&fromDate=${startDate}`;
  }
  if (endDate) {
    str = `${str}&toDate=${endDate}`;
  }
  if (itemRedeemed) {
    str = `${str}&itemRedeemed=${itemRedeemed}`;
  }
  try {
    let token = localStorage.getItem('vendorToken');
    const response = await axios({
      method: 'GET',
      url: `${baseUrl}/merchantVouchers${str ? `?${str}` : ''}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
  }
});

export const redemptionSlice = createSlice({
  name: 'redemptionSlice',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getMerchantVouchers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getMerchantVouchers.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action?.payload?.api?.responseCode === 2040) {
        state.redemptions = action?.payload?.result?.content;
      } else {
        state.redemptions = [];
      }
    });
    builder.addCase(getMerchantVouchers.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default redemptionSlice.reducer;
