import { configureStore } from '@reduxjs/toolkit';
import dashboardSlice from './DashboardSlice';
import profile from './profileSlice';
import catalogueSlice from './catalogueSlice';
import redemptionSlice from './redemptionSlice';
import paymentSlice from './paymentSlice';

export const store = configureStore({
  reducer: {
    dashboardSlice,
    profile,
    catalogueSlice,
    redemptionSlice,
    paymentSlice,
  },
});
